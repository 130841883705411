@font-face {
    font-family: Avenir-Light;
    src: url(../fonts/AvenirLTStd-Light.otf);
}

@font-face {
    font-family: Avenir-Book;
    src: url(../fonts/AvenirLTStd-Book.otf);
}

@font-face {
    font-family: Avenir-Roman;
    src: url(../fonts/AvenirLTStd-Roman.otf);
}

@font-face {
    font-family: Avenir-Black;
    src: url(../fonts/AvenirBlack.otf);
}


#login:hover, #register:hover{
	background-color: transparent!important;
	text-decoration: underline;
}
.btn-disabled {
    opacity: 0.6;
    cursor: no-drop !important;
    pointer-events: none;
}

#nprogress {
	z-index: 9999!important;
}
#nprogress .bar {
  background: #7bc143!important;
  height: 3px;
}

body {

	font-family: "Avenir-Light", "Calibri", Helvetica,Arial,sans-serif !important;
	font-weight: 400;
	font-size: 18px!important;
	position:relative;
	color: #4b4a4a;
}

.details-box h3 {
    padding-left: 0!important;
    padding-right: 0!important;
    padding-top: 0!important;
    font-family: inherit!important;
    color: inherit!important;
    font-size:24px!important;
}


.details-box .inner-box{
	-webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,.18)!important;
    -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,.18)!important;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,.18)!important;
    padding: 15px 35px 35px;
}
.do-invite {
line-height: 2!important;
cursor: pointer!important;
}

header {
	background-color:rgba(6,141,194,0.7)!important;
}

#hamburguer {
	margin: 15px!important;
}

.navbar-header > a {
	background-color: white;
    display: block;
    padding: 20px 30px;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,.75)!important;
	-moz-box-shadow: 0 0 10px 2px rgba(0,0,0,.75)!important;
	box-shadow: 0 0 10px 2px rgba(0,0,0,.75)!important;
}

.logo-header {
	width: 325px;
}
#bg-loading{
	background-color: #000;
	height: 100%;
	left: 0;
	opacity: .8;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9998;
	display:none;
}

#loading{
	position:fixed;
	top: calc(50% - 140px);
	width: 100%;
	max-width: 562px;
	max-height: 270px;
	height: 100%;
	background-color: #f6f6f6 !important;
	text-align: center;
	padding: 0;
	z-index: 9999;
	display: none;
}

#loading .brand-logo,
#loading .load{
	display:block;
	text-align: center;
	clear:both;
}

#loading .brand-logo{
	padding-bottom: 20px;
}

#loading .brand-logo img {
	border:3px solid black;
	width: 50px;
}


#loading .load{
	color: #5b5b5b;
	text-transform: uppercase;
	font-size: 18px;
	border-top: 1px solid #6f7c85;
	border-bottom: 1px solid #6f7c85;
	padding: 0px;
}

#loading .load img{
	height: 100%;
	width: 100%;
}

@media(max-width: 769px){
	#loading {
	    top: 10%;
	    width: 100%;
	    padding: 0;
	    height: initial;
	}

	#loading .brand-logo img{
		width: 80%;
		max-width: 75px;
	}
}

@media(min-width: 768px){
	#loading {
		left: calc(50% - 281px);
	}

	header.top .dropdown-menu a {
		border-bottom: none!important;
	}
}



@media (min-width: 320px) and (max-width:767px) {
	.active.tab-option {
		width:100%;
		border: 1px solid #d6d6d6;
	}
	.active.tab-option > a{
		border:none!important;
	}
	#loading {
	    left: 0!important;
	    right: 0!important;
	}
	.navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }

    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-collapse.collapse.in{
    	display: block!important;
	}
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }

}

body.full header{
	display: none;
}

header.top{
	position:fixed;
	z-index: 3;
}
header, footer {
	display:block;
}
* {
	font-family: 'Avenir-Book', sans-serif;
	font-size: 16px;
	line-height: 1.6;

}

#background-beach,
#background-profile{
	background-image: url('images/beach-relax-filtered.jpg');
	height: 500px;;
    background-position: center center;
    background-size: cover;
    display:block;
    color:white;
    font-size:30px!important;
}

#background-profile {
	background-image: url('images/beach-rocks.jpg');
}


a:hover,
a:link,
a:visited
a:active {
	text-decoration: none;
	cursor:pointer;
}
body {
	color: #6a6a6a;
}

p{
    font-size: 14px;
    line-height: 1.8;
}

header {
	background-color: transparent;
    color: #fff;
    padding: 10px 0px;
    position: relative;
	display: block;
    width: 100%;
}

header a.main-logo{
	position: absolute;
    top: 28px;
    left: 0;
    right: 0;
}

header a.main-logo span {
	background-color: white;
    border: 4px solid black;
    padding: 20px;
    display:inline-block;
}

header a.main-logo img {
	max-width:90px;
}

header a {
	color: #ffffff;
}
header a:hover {
	color: #5b5b5b;
}


#register, #login {
	padding-right: 40px;
	background-repeat: no-repeat;
	background-position: calc(100% - 15px) center;
	background-size:20px;
	text-transform: uppercase;
}

#register{
	background-image: url('images/check-icon.png')!important;
	border-right: 1px solid white;
}

#login{
	background-image: url('images/login-icon-white.png')!important;
	padding-right: 45px;
}

#register{
	background-image: url('images/user-icon.png');
	padding-right: 45px;
}

#beach-background {
	background-image: url('images/beach-relax-background.jpg');
    display: block;
    height: 560px;
    background-size: cover;
    background-position: center;
}

.description h2, .description {
	color: white;
}
.description h2 {
	padding:0;
	font-size: 80px;
	margin:10px;
}

.description {
    top: 30%;
    font-size: 30px;
    text-align: center;
    position: relative;
    bottom: 10%;
}

.description hr {
	width: 300px;
    margin-bottom: 20px;
}

hr {
	width: 50px;
    background-color: #DADADA;
    padding-top: 1px;
    margin-bottom: 30px;
    margin-top: 0;
    box-shadow: none;

}
.beige-belt,
.white-belt,
.lightblue-belt {
	padding: 25px;
	font-size:18px;
}

.beige-belt h2,
.white-belt h2,
.lightblue-belt h2 {
	font-size: 36px;
    margin-bottom: 30px;
}

.white-belt h2 {
	margin-bottom: 40px;
	color: #0190ca!important;
}

.white-belt h3 {
	margin-top:0px;
	color: #0190ca!important;
	font-family: 'Avenir-Book',sans-serif!important;
	font-weight: 600;
}

.white-belt p {
    color: #4b4a4a;
    font-size: 1.6rem;
}

.lightblue-belt hr{
	background-color:#6d6c6c;
	border:none;
	margin-bottom: 30px;
}

.white-belt {
	padding: 60px 10px;
}

.options a{
	color: #5b5b5b;
	font-weight: 600;
	font-family: 'Avenir-Book', sans-serif;
}
.beige-belt {
    background-color: #f6f6f6;
    font-size: 22px;
    color: #5b5b5b;
    font-family: 'Avenir-Light',sans-serif;
    padding: 20px 25px 15px 25px;
}

.lightblue-belt {
    background-color: #0190ca;
    padding-top:80px;
    padding-bottom:80px;
}

h3 {
	font-family: 'Avenir-Light', sans-serif;
	padding: 15px 10px;
	font-size:20px;
	color: #1b1b1b;
}

.program-prices h2{
	font-size: 22px;
}

h2 {
	font-family: 'Avenir-Book', sans-serif;
	font-weight: 600;
	font-size:44px;
	color: #5b5b5b;
	margin-bottom: 50px;
}

hr {
	width: 50px;
	background-color:#DADADA;
	padding-top: 1px;
	margin-bottom: 60px;
	box-shadow: none;
}
.program-options {
	padding-top:85px;
	padding-bottom:80px;
}
.program-options h2{
	font-size: 28px;
	margin-bottom: 0px;
	font-family: 'Avenir-Book', sans-serif;
}


.program {
	margin: 30px 0;
}

.program > div {
	padding-left:0;
	padding-right:0;
}
.program .title {
	color: black;
	font-size: 20px;
	font-family: 'Avenir-Light', sans-serif;;
}
.program .price {
	color: black;
	font-size: 22px;
}
footer {
	background-color:#03416b;
	color: #abc0cd;
	padding: 50px 25px 40px 25px;
	font-family: 'Avenir-Light', sans-serif;
}


footer a,
footer a:hover,
footer a:visited,
footer a:link,
footer a:active {
	color:inherit;
}

footer a:hover{
	text-decoration: underline;
}

footer ul{
	list-style: none;
}

footer ul li {
    /* display: inline-block; */
    margin-left: 2rem;
}

footer ul li:before {
	content: '\00B7\00a0';
}

footer div.left {
    text-align: left;
}

footer div.right {
    text-align: right;
}

.bordered {
	background-color: #f6f6f6;
	padding: 20px;
	margin-top:5px;
}

#register-modal .title,
#login-modal .title{
    padding-right:10%;
    color: #0190ca;
    font-weight: 600;
    padding:0;
    font-size:2rem;
}
#register-modal .title span,
#login-modal .title span{
	display:inline-block;
	background-image: url('images/user-icon-light-blue.png');
	background-repeat: no-repeat;
    background-size: 22px;
    background-position: calc(80%) top;
    height: 22px;
    width: 22px;
}
*[data-role="submit"],
input[type="submit"],
button.btn-payment,
.btn-cancel,
a.btn-continue{
	color: white!important;
    background-color: #7bc143!important;
    border-radius: 2px;
    padding: 10px;
    margin-top:20px;
    cursor:pointer;
    border:none;
    text-align: center;
    width:100%;
}

.btn-cancel {
	background-color: #bfbfbf!important;

}

.btn-continue {
	display:block!important;
}

.btn-continue:hover{
	text-transform: inherit!important;
    font-size: inherit!important;

}
#register-modal .title.guess span, #login-modal .title.guess span {
    background-image: url(images/Plus.png);
    height: 23px;
    transform: scale(2.5);
    margin: 5px 5px 0px 5px;
}
button.btn-payment {
	width: calc(100% - 20px);
}
*[data-role="change"]:hover,
*[data-role="change"]:link,
*[data-role="change"]:active,
*[data-role="change"]:visited{
	text-transform: uppercase;
	color:#5b5b5b;
	font-size:14px;
	text-decoration: underline!important;
}
a.do-fb:hover,
a.do-fb:link,
a.do-fb:active,
a.do-fb:visited {
	color: white;
    padding: 10px 40px 10px 10px;
    text-transform: none;
    background-color: #529ad3;
    width: 100%;
    display: block;
    font-size: 18px;
    border-radius: 2px;
    background-repeat: no-repeat;
    background-image: url(images/facebook.png);
    background-position: 95% center;
}

.modal-body {
	color: white;
}

.form-control {
	border-radius: 0;
	text-align: center;
	color: #5b5b5b;
	font-size: 16px;
}
#contact-us {
	padding-top: 80px;
    background-size: cover;
    color: white;
    background-position: center;
    padding-bottom: 110px;
    background-image: url(images/couple_blue_filter.png)!important;
}

#contact-us hr.bold {
	margin:60px 0 30px 0;
	width: 100%;
}


#contact-us hr.vertical {
    height: -webkit-fill-available;
    display: block;
    padding: 100% 0;
    vertical-align: middle;
    margin: 0;
    width: 1px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50%;
    max-height: 210px;
}
#contact-us h2 {
    color: white;
}

#contact-us .contact-numbers {
	font-weight: 600;
}

#contact-us div.name {
	margin-top: 80px;
}

#contact-us span{
	font-size: 22px;
	font-family: "Avenir-Light";
}

#contact-us .form-control {
	text-align: left;
    border: none;
    background-color: rgba(254,254,254,.3);
    padding: 20px 40px;
    font-size: 1.8rem;
    font-family: "Avenir-Book";
    color: white!important;
}

#contact-us .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: "Avenir-Book";
	color: white!important;
}
#contact-us .form-control::-moz-placeholder { /* Firefox 19+ */
    font-family: "Avenir-Book";
    color: white!important;
}
#contact-us .form-control:-ms-input-placeholder { /* IE 10+ */
    font-family: "Avenir-Book";
    color: white!important;
}
#contact-us .form-control:-moz-placeholder { /* Firefox 18- */
    font-family: "Avenir-Book";
    color: white!important;
}


.border-left {
    border-left: 1px solid gray!important;
    padding-left: 40px;
}
textarea.form-control {
	height:250px;
}
.form-control{
	margin-top:10px;
}
.form-control:not(:last-child){
	margin-top:10px;
}

.nopadding {
	padding: 0;
}

.close{
	opacity: 0.8;
}
.close span {
	display:block;
	background-image: url('images/exit.png');
	background-size: cover;
	background-repeat: no-repeat;
	width: 28px;
	height: 28px;
}

::-webkit-input-placeholder {
   color: #5b5b5b!important;
}

:-moz-placeholder { /* Firefox 18- */
   color: #5b5b5b!important;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #5b5b5b!important;
}

:-ms-input-placeholder {
   color: #5b5b5b!important;
}

.reset, .full{
	background-color: #f6f6f6;
}

.reset div[data-role="response"]{
	margin-top: 5%;
}

.full footer {
	position:absolute;
	bottom:0;
	width:100%;
}

header #languages span{
  display: inline-block;
  border-radius: 5rem;
  line-height: 2rem;
  width: 2.9rem;
  text-align: center;
  padding: 0.5rem 0px;
  height: 2.9rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  vertical-align: middle;
}

header #languages ul {
  text-align: right;
}

header #languages span {
  font-family: "Poppins-SemiBold";
  font-weight: 600;
  color: #465664;
  background-color: #d3d6da;
}

header #languages span.en {
  background-image: url("images/languages/en.png");
}

header #languages span.es {
  background-image: url("images/languages/es.png");
}

header #languages span.de {
  background-image: url("images/languages/de.png");
}

header #languages span.fr {
  background-image: url("images/languages/fr.png");
}

header #languages span.it {
  background-image: url("images/languages/it.png");
}

header #languages span.chi {
  background-image: url("images/languages/chi.png");
}

header #languages span.pt {
  background-image: url("images/languages/pt.png");
}

/*Select currency and language*/
.form-control.currency,
.form-control.language {
	border-radius: 0; -webkit-appearance: none;text-align:center;-moz-appearance: none;
}

#message form {
	color: #5b5b5b;
}

.modal-backdrop.in {
	opacity: 0.8;
}

.navbar-toggle{
	padding-top: 0px;
}

header .navbar-header {
	max-width: 250px;
	line-height: normal;
    margin-top: 10px;
    vertical-align: middle;
}

#terms-conditions h2,
#privacy-policies h2 {
    font-size: 24px;
    margin: 0;
	padding-bottom: 20px;
	text-transform:uppercase;
}

#terms-conditions .bordered,
#privacy-policies .bordered{
    padding: 40px;
}
#terms-conditions > div.modal-dialog>div[data-role="response"] >
div.bordered, #privacy-policies > div.modal-dialog>div[data-role="response"] > div.bordered {
    box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.75);
}

.tab-option{
	width:100%;
}

.tab-option a {
	color: #5b5b5b;
}
.tab-option .active a {
	font-weight: 800;
}

.tab-panel .tab-content {
	margin-top:-5px;
}


.tab-panel {
	background-color:#F6F6F6;
}
.tab-panel .bordered {
	background-color: white;
}
.tab-panel hr {
	margin: 20px auto;
    width: calc(40% - 15px);
}

#register-tab .bordered{
	padding-bottom: 40px!important;
}

.tab-panel div[aria-controls="register-tab"]{

}
@media (-webkit-min-device-pixel-ratio:0)
{
    .form-control.currency {padding-left:calc(50% - 60px);}
    .form-control.language {padding-left: calc(50% - 35px);}
}

@-moz-document url-prefix() {
    .form-control.currency,
    .form-control.language {padding-left:0;}
}


@media (max-width: 768px){
	#background-beach{
		height: 500px;
	}

	#background-beach .description h2 {
		font-size:30px;
	}

	#background-beach .description {
		font-size:22px;
	}

	footer div.left, footer div.right {
		float: none!important;
		text-align: center;
	}

	footer div.right li,
	footer div.right ul{
		padding:0;
		margin:0;
		display:block;
	}

	#carousel-slider .carousel-caption h2 {
		font-size: 6rem!important;
	}

	.plan-description {
		min-height: inherit!important;
	}

	.plans>ul>li {
		width: 90%!important;
		margin-right: auto;
		margin-left: auto;
		display: block!important;
		border-right: none!important;
		border-bottom: 1px solid #DADADA;
	}

}

#renew .bordered {
	margin:0;
}

#renew .tab-option {
	width: 100%;
	margin: 0;
	padding: 0;
}

#renew .tab-option a{
	margin: 0!important;
	border-radius: 0!important;
	text-align: center;
	font-weight: 600;
}

#renew hr {
	margin-bottom: 15px;
	margin-top: 15px;
}

#renew  p {
	color:gray;
}

#renew  .tab-content {
	font-size:1.6rem;
	color:#5b5b5b!important;
}

#expiration-modal .modal-body{
	padding: 5px;
	padding-bottom: 30px;
}

#expiration-modal .modal-body p{
	padding:30px 15px;
}

#expiration-modal .abuttons a{
	display: block;
	margin: 0;
}

#expiration-modal .modal-body {
	display:table;
}

#expiration-modal .tab-option {
	text-transform: uppercase;
	width: 100%;
	font-weight: 600;
	border-bottom: 1px solid #DADADA;
}

#expiration-modal .tab-option  a{
	border:none;
}

#expiration-modal .modal-body .plans p{
	padding:0;
	margin-top:30px;
}


.carousel-control.right span{
	background-image:url(images/right-arrow.png)!important;
}

.carousel-control.left span{
	background-image:url(images/left-arrow.png)!important;
}
.carousel-control {
	background-repeat: no-repeat;
	background-size:contain;
	background-position: center center;
	width:35px;
	height:35px;
	display:block;
	top: calc(50% - 20px);
	position:absolute;
}

.carousel-control  span {
    display: block;
    width: 35px;
    height: 35px;
    background-size: contain;
}
@media (max-width: 767px){
	header a.main-logo {
    	position: relative;
	    top: 0px;
	    left: 10%;
	    right: 0;
	}


	header .navbar-header {
		max-width: 100%!important;
		margin:0!important;
		padding:0;
	}

	.navbar-toggle {
		margin:0;
		padding:0;
	}

	.logo-header {
	    max-width: 200px;
	}

	header {
	    position: fixed;
	    border-bottom: 1px solid white;
	    background-color: rgba(1,144,202,0.8)!important;
	    z-index: 2;
	}

	header a.main-logo span{
		padding: 10px;
	}

	header a.main-logo img {
		width: 70px;
	}

	.border-left {
		border-left: 0;
		padding:0;
	}
}

.videoWrapper {
	top: 45px;
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


/* This parent can be any width and height */
.block {
  text-align: center;
}

/* The ghost, nudged to maintain perfect centering */
.block:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em; /* Adjusts for spacing */
}

/* The element to be centered, can
   also be of any width and height */
.centered {
  display: inline-block;
  vertical-align: middle;
  width: 300px;
}

.carousel-control.right,
.carousel-control.left{
	background-image: none!important;
}

.carousel .item {
	min-height: 500px;
}


.carousel .item .carousel-caption {
	text-shadow: none;
    color: inherit;
}

.checkbox label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.3em;
    height: 1.3em;
    float: left;
}

.radio .cr {
    border-radius: 50%;
}

.radio, .checkbox{
	margin-bottom: 0;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 20%;
}

.radio .cr .cr-icon {
    margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all .3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
    opacity: .5;
}

.checkbox i.destination-name{
	display: inline-block;
    width: 170px;
    vertical-align: top;
    white-space: pre-wrap;
    overflow: inherit;
}

.navbar-nav a {
	color:#fff!important;
}

.fb-linkto a{
	color: white;
	background-color: #529ad3;
    padding: .9rem;
    display: block;
}

.link-right{
	line-height: 3;
	color:inherit;
}

.link-right:after{
	content: "\203A";

}

li.open a {
    background-color: transparent!important;
}


header.top .dropdown-menu  a{
    padding: 8px 15px;
    border-bottom: 1px solid white;
}

.dropdown-menu {
	width:100%;
	background-color: inherit!important;
	border:inherit!important;
	box-shadow: inherit!important;
	text-transform: uppercase;
}

header.top .dropdown-menu{
	background-color: #000!important;
    border: 1px solid white!important;
    margin-top: 10px;
    border-radius: 0!important;
    padding: 0;
}

.details-box {
    background-color: white;
    border: .1rem solid #dededf;
    margin: 5px;
    padding: 3rem 2rem;
    color: inherit;
    margin: 0.5rem .5rem 0 0;
    text-align: left;
}

.breadcrumb {
	background-color:#e0e0e0;
}

.breadcrumb a{
	color: #000;
	text-transform: uppercase;
}

.details-box h4{

}
 .details-box a[data-role="change"] {
    border-radius: 0.2rem!important;
    cursor: pointer!important;
}


.details-box label {
	padding:0;
}


.details-box .input-group {
	display:block;
}

.details-box .form-control {
	width:100%;
	margin:0;
	margin-bottom: 10px;
	text-align: left;
}

.details-box a[data-role="change"], .details-box a[data-role="change"]:link, .details-box a[data-role="change"]:hover, .details-box a[data-role="change"]:active, .details-box a[data-role="change"]:visited, .details-box div[data-role="submit"], .details-box div[data-role="submit"]:link, .details-box div[data-role="submit"]:hover, .details-box div[data-role="submit"]:active, .details-box div[data-role="submit"]:visited, #avatar a[data-role="change"], #avatar div[data-role="submit"],
.btn-rewards, .btn-rewards:hover, .btn-rewards:visited, .btn-rewards:link {
    width: 100%;
    background-color: #7bc143;
    display: block;
    color: white;
    text-align: center;
    padding: 1rem 2rem;
    cursor: pointer;
    font-size: 1.6rem;
    line-height: 1.6rem;
    border-radius: .2rem;
    text-transform: capitalize!important;
}

.subtitle h3{
	color:inherit;
	padding: 3rem;
    font-size: 2.8rem;
}



.change-avatar,
.avatar-thumb {
	height: 200px;
	width: 200px;
	background-position: center center;
	background-size: cover;
	border-radius: 100px;
	display: block;
	position: static;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	display: block;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	margin-bottom: 2rem;

}

.avatar-thumb {
	margin: 0!important;
	top: 0;
	vertical-align: text-top;
	padding: 0;
}

.change-avatar img,
.avatar-thumb img{
	display: inline;
	width: 100%;
	object-fit: cover;
	height: 200px;
	left: 0;
	right: 0;
	position: absolute;
}

.avatar-thumb img{
	height: inherit;
    margin: 0;
    bottom: 0;
}

.pick {
	display: block;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height:50px;
    padding: 0 0 6px;
    font-weight: 300;
}

#avatar input[type=file]{
	display:none;
}

.fit-in {
    background-position: center center;
    background-size: cover;
}

.tab-panel.modal-body {
	color:inherit;
}


#register-modal .modal-dialog, #message .modal-dialog {
    width: 750px;
}
#title-plan{
	padding-left: 5rem;
    padding-right: 5rem;
    color: #0991C9;
}
#title-plan > h1{
	font-weight: 800;
}

#description-plan{
	padding-left: 5rem;
    padding-right: 5rem;
}
.plans {
	flex-direction: column;
	float: left;
	width: 33.3%;
	padding: 8px;
}

.plans .description {
	text-transform:uppercase;
	font-weight:600;
	font-size:20px;
	min-height: 100px;
	padding-top:30px!important;
}
.plans .information {
	list-style-type: none;
	border: 1px solid #eee;
	margin: 0;
	padding: 0;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	min-height: 400px;
	height:auto;
	position:relative;
}

.plans .information:hover,
#most-popular .information{
	box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
}

.plans .information .header {
	color: white!important;
	font-size: 1.9rem;
	font-weight: 600;
	padding: 15px;
}

.plans .information li {
	padding: 15px 20px;
	text-align: center;
	color:#5b5b5b!important;
	font-size:1.6rem;
	font-weight: 600;
}

.plans .information li:last-of-type {
	margin-top: auto;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.plans .checkbox label,
.plans .checkbox{
	margin:0;
	padding:0;
}

.plans .checkbox span.cr {
	color:#21abbf!important;
}
.plans .price{
	font-size: 5rem!important;
	padding: 0!important;
}

.plans .price span:first-of-type{
	font-size: 2.2rem;
	vertical-align: middle;
}

.btn-payment {
	border: none;
	color: white;
	padding: 10px 25px;
	text-align: center;
	text-decoration: none;
	font-size: 18px;
	border-radius:2px;
}

.plans .header {
	position: relative;
	background: #88b7d5;
}
.plans .header:after {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-top-color: #88b7d5;
	border-width: 18px;
	margin-left: -18px;
}

.plans .btn-payment {
	margin-top: 0!important;
	margin-bottom: 20px!important;
}
input[type='radio']:after {
	width: 20px;
	height: 20px;
	border-radius: 15px;
	top: -2px;
	left: -5px;
	position: relative;
	background-color: #ffffff;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 3px solid #757575;
}

input[type='radio']:checked:after {
    width: 20px;
    height: 20px;
	border-radius: 15px;
	top: -2px;
	left: -5px;
	position: relative;

	content: '';
	display: inline-block;
	visibility: visible;
	border: 3px solid #757575;
}
#most-popular input[type='radio']:checked:after{
	background-color: #21abbf !important;
}
#most-popular .message,
#most-popular .selectplan{
	color: #21abbf !important;
}
#most-popular .header,
#most-popular .btn-payment{
	background-color:#21abbf!important;
}

#most-popular .header:after{
	border-top-color:#21abbf;

}
#best-deals input[type='radio']:checked:after{
	background-color: #fac665 !important;
}
#best-deals .message,
#best-deals .selectplan{
	color: #fac665!important ;
}
#best-deals .header,
#best-deals .btn-payment{
	background-color:#fac665!important;
}

#best-deals .header:after{
	border-top-color:#fac665;
}

#standard li:nth-child(4){
	padding:10px 10px 10px 25px!important;
}
#standard input[type='radio']:checked:after{
	background-color: #acca5b !important;
}
#standard .message,
#standard .selectplan{
	color: #acca5b !important;
}
#standard .header,
#standard .btn-payment{
	background-color:#acca5b!important;
}

#standard .header:after{
	border-top-color:#acca5b;
}

#standard .information,
#best-deals .information{
	box-shadow: 0 1px 12px 0 rgba(0,0,0,0.2);
}

.modal-body .reactivation{
	color: gray;
}

.modal-body .reactivation a:not(.btn-continue) {
	color:inherit!important;
	font-size:inherit!important;
	font-weight: 600;
}
.reactivation .bordered {
	padding-top:60px;
	padding-bottom:60px;
}
.reactivation .uppercase {
	text-transform: uppercase;
}

.reactivation hr {
	margin-top: 15px;
	margin-bottom: 20px;
}

.reactivation img {
	display: block;
	margin: 0 auto;
	max-width: 75px;
	margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {
	.plans{
		width: 100%;
	}
	#register-modal .modal-dialog,
	#message .modal-dialog {
		width: unset;
	}
}


/*.plans {
	display:table;width:100%;
}

.plans > ul {
	display:table-row
}

.plans > ul > li{
    display: table-cell;
    padding: 30px 10px;
    width: 30%;
}

.plans .sub-title {
	font-family: "Avenir-Black", "Calibri", Helvetica,Arial,sans-serif !important;
	font-size: 1.8rem;
    height: 71px;
    vertical-align: bottom;
}

.plans .price {
	font-size:48px;
	font-family: "Avenir-Black", "Calibri", Helvetica,Arial,sans-serif !important;
}

.plans .price span {
	font-size: 24px;
	vertical-align: middle;
}

.plan-description {
	min-height: 220px;
}


.plans>ul>li:not(:last-child) {
	border-right:1px solid #DADADA;
}
*/

#expiration-modal .modal-body{
	padding: 5px;
	padding-bottom: 30px;
}

#expiration-modal .modal-body p{
	padding:30px 15px;
}

#expiration-modal .abuttons a{
	display: block;
	margin: 0;
}

#expiration-modal .modal-body {
	display:table;
}

#expiration-modal .tab-option {
	text-transform: uppercase;
	width: 100%;
	font-weight: 600;
	border-bottom: 1px solid #DADADA;
}

#expiration-modal .tab-option  a{
	border:none;
}

#expiration-modal .modal-body .plans p{
	padding:0;
	margin-top:30px;
}

#terms-conditions [class="text-justify"],
#privacy-policies [class="text-justify"]{
	overflow: scroll;
    height: 300px;
    padding-left:0;
}

#terms-conditions ol {
	list-style: none;
	margin:0;
}

#contact-us *[data-role="submit"]{
    font-size: 3.2rem;
    color: white;
    border-radius: 5px;
    margin-bottom: 15px;
    line-height: initial;
    padding: 10px;
    background-color: #0190ca!important;
    -webkit-box-shadow: 3px 2px 9px 2px rgba(0,0,0,.35)!important;
    -moz-box-shadow: 3px 2px 9px 2px rgba(0,0,0,.35)!important;
    box-shadow: 3px 2px 9px 2px rgba(0,0,0,.35)!important;
}
#reset-modal .bordered {
	font-size: 1.8rem!important;
}
#reset-modal .bordered,
*[data-role="submit"]{
	text-align: center!important;
	text-transform: capitalize;
}
#reset-modal h3{
	color: white!important;
}

#linktofb button {
	display:none;
}

.alert-danger{
	margin-top:15px;
}

#about-us {
    border-top: 10px solid white;
    border-bottom: 10px solid white;
    background-image: url('/images/green_beach.png');
    color: white;
    padding-top: 80px;
    background-position: calc(30%) top;
    padding-bottom: 90px;
    background-size: auto;
}

#about-us h2{
	color:white;
}
#about-us p{
    font-size:2.1rem;
    margin-bottom: 2rem;
}

/*SLIDES*/
.carousel-indicators {
	z-index: 2;
}
.carousel-caption {
    left: 15%;
    right: 15%;
    top: 35%;
    bottom:10%;
}

#carousel-slider .carousel-caption,
#carousel-slider .carousel-caption h2{
	color: white!important;
}

#carousel-slider .carousel-caption h2 {
	font-size: 11rem;
	margin:0;
}
#carousel-slider .carousel-caption span {
	font-size: 3rem;
	font-family: 'Avenir-Book';
	font-weight: 600;
}

.main .item {
	background-position: bottom;
	background-size:cover;
	background-color:#1791C8;
	background-image: url('images/sliders/bahamas.jpg');
}
.main #carousel-1{
	background-image: url('images/sliders/bahamas.jpg');
}

.main #carousel-2{
	background-image: url('images/sliders/cancun_beach.jpg')!important;
}
.main #carousel-3{
	background-image: url('images/sliders/cambodia_sun.jpg')!important;
}
.main #carousel-4{
	background-image: url('images/sliders/beach_thailand.jpg')!important;
}

.main #carousel-5{
	background-image: url('images/sliders/swimming_girls.jpg')!important;
}

.more-info {
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
}

a[data-route="forgot-password"] {
	text-transform: capitalize!important;
}
/*Tabs*/
.nav-tabs>li {
	border-bottom: 1px solid #ddd;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus {
    color: #529ad3!important;
    font-weight: 800!important;
    font-family: "Avenir-Book", "Calibri", Helvetica,Arial,sans-serif !important;
}

.program a {
	width: calc(100% - 30px);
	display:block;
	margin: 0 auto;
}
.program .name {
    position: absolute;
    bottom: 30%;
    top: 35%;
    left: 0;
    right: 0;
    color: white;
    text-transform: none;
    font-size: 2rem;
}

.program .name span {
    height: 40px;
    width: 40px;
    display: block;
    margin: 0 auto 5px auto;
    background-size: 100%;
    background-repeat: no-repeat;
}

.program #reservations .name span {
	background-image: url('images/check-calendar-icon.png');
}

.program #profile .name span {
	background-image: url('images/user-icon.png');
}

.program #rewards .name {
	top: 25%;
    width: calc(100% - 30px);
    margin: auto;
}
.program #rewards .name span {
	background-image: url('images/money-icon.png');
}

.program .name  .btn {
	display: block;
    background-color: #0190ca;
    width: 100%;
    max-width: 130px!important;
    margin: auto;
    font-size: 1.6rem;
    padding: 5px 30px;
    border-radius: 0.2rem;
	-webkit-box-shadow: 0 0 12px 0px rgba(0,0,0,.6)!important;
	-moz-box-shadow: 0 0 12px 0px rgba(0,0,0,.6)!important;
	box-shadow: 0 0 12px 0px rgba(0,0,0,.6)!important;

}

.profile #rewards img{
    display: block;
    margin: 0 auto;
    max-width: 100px;
    width: 100%;
}

.profile #rewards .link-right {
	color: #0190ca!important;
}

.profile #rewards .description {
	width: 75%;
    margin: 0 auto;
}
#rewards h3,
.available h4
{
	color: #0190ca!important;
	font-size:2rem;
	font-family: 'Avenir-Book',sans-serif;
	font-weight: 600;
}

#rewards h3 {
	font-size:2.4rem!important;
}
.available table{
    width: 100%;
    padding: 15px 0 10px;
    display: block;
}

.available table tr,
.available table tbody{
	width:100%;
}

.available table tbody{
	display:table;
}

.available table tr {
	width:100%;
	display:table-row;
}

.available table td:first-child{
	display:table-cell;
	width:40%!important;
}

.flat-windows .modal-content {
	border-radius: 0px;
	margin-top: 10px;
}

/**/
#purchaseRewards .modal-wrapper {
	color: #7a858f!important;
	padding-bottom: 25px;
}
#purchaseRewards h2{
	font-size: 2rem;

}

#purchaseRewards select,
#purchaseRewards input {
	width:100%;
	text-align-last:center;
}

#purchaseRewards hr {
	width: 90%;
	margin: 0 auto 15px;
}

#purchaseRewards .input-group {
	display:inline-block;
	width: 45%;
}

#payment-type img {
	height: 20px;
	width:auto;
}

#payment-type .radio label{
	padding:0 0 15px 0;
}

#purchaseRewards label[for="ccv"] a{
	display:unset;
}

#purchaseRewards label[for="amount"],
#purchaseRewards label[for="payment_on"],
#purchaseRewards label[for="cnumber"],
#purchaseRewards label[for="ccv"],
#purchaseRewards label[for="expiration"],
#purchaseRewards label[for="birthdate"],
#purchaseRewards label[for="name_on_card"],
#purchaseRewards label[for="phone"],
#purchaseRewards label[for="address"],
#purchaseRewards label[for="country"],
#purchaseRewards label[for="state"],
#purchaseRewards label[for="city"],
#purchaseRewards label[for="zip_code"]{
	width:45%;
	vertical-align: text-bottom;
}


#header #user .user-icon {
  display: inline-block;
  border-radius: 5rem;
  line-height: 2rem;
  width: 2.9rem;
  text-align: center;
  padding: 0.5rem 0px;
  height: 2.9rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  vertical-align: middle;
}


#header #user .user-icon {
  margin-right: 0.5rem;
  background-image: url("images/user-icon.png");
}

.lang-en #carousel-slider .carousel-caption h2{
	font-size: 9rem!important;
}

.form-register {
	margin-left:1.5rem;
}

.change-language {
	    background-color: transparent!important;
    border: 0!important;
}

#languages .dropdown {
	    line-height: 3;
}

header.top .dropdown-menu a {
	background:inherit;
}

.nav.navbar-nav .open>a, .nav.navbar-nav .open>a:focus, .nav.navbar-nav .open>a:hover,
.nav.navbar-nav>li>a:focus, .nav.navbar-nav>li>a:active,
#languages:hover,
#register:hover,
#login:hover{
	background-color:rgb(103, 188, 223)!important;
}


#languages span.arrow{
	background-image: url(v2/images/arrow_down_white.png)!important;
    background-repeat: no-repeat!important;
    width: 15px;
    height: 15px;
    background-color:transparent!important;
    background-size: inherit!important;
    background-position: center top;
}

.open #languages span.arrow{
	background-image: url(v2/images/arrow_up_white.png)!important;
}

#languages {
	border-radius: 5px 5px 0 0;
    min-width: 160px;
    text-align: center;
	margin-bottom: -10px;
    padding-bottom: 25px;
}

#languages .dropdown-menu a{
	border: none!important;
	background-color:rgb(103, 188, 223)!important;
}


#languages .dropdown-menu a:hover {
	/*background-color:1798c1!important;*/
}

header.top .dropdown-menu {
	background-color:rgb(103, 188, 223)!important;
    padding-bottom: 15px!important;
	border: none!important;
}

#register-modal .tab-option {
	text-transform: uppercase!important;
}

#register-modal p a {
	font-size:1.4rem!important;
	color:#529ad3!important;
	text-decoration: underline;
	font-weight: 600;
}
